import React, { useContext } from "react";
// import HomeV1 from "../../components/home-v1";
// import HomeV2 from "../../components/home-v2";
// import HomeV3 from "../../components/home-v3";
// import HomeV4 from "../../components/home-v4";
// import HomeV5 from "../../components/home-v5";
import { Helmet } from "react-helmet";
import EmpresaContext from "../../context";
import Loadable from "react-loadable";
import Loading from "../../components/global-components/Loading";

const HomeV1 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v1" */ "../../components/home-v1"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v1"]
});

const HomeV2 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v2" */ "../../components/home-v2"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v2"]
});

const HomeV3 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v3" */ "../../components/home-v3"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v3"]
});

const HomeV4 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v4" */ "../../components/home-v4"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v4"]
});

const HomeV5 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v5" */ "../../components/home-v5"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v5"]
});

const HomeV6 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v6" */ "../../components/home-v6"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v6"]
});

const HomeV7 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v7" */ "../../components/home-v7"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v7"]
});

const HomeV8 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "home-v7" */ "../../components/home-v8"),
  loading() {
    return <Loading height="100vh" />;
  },
  modules: ["home-v8"]
});

const Home = () => {
  let context = useContext(EmpresaContext);
  return (
    <>
      <Helmet>
        <title>
          {context.titulo_site || context.nome_fantasia || context.nome}
        </title>
        <meta
          property="description"
          content={
            context.descrição ||
            context.frase_site ||
            `Site imobiliario de ${context.nome_fantasia || context.nome}`
          }
        />
        <meta name="keywords" content={context.palavras_chave || ""} />
        <meta property="og:locale" content="pt_BR" />
        <meta
          property="og:url"
          content={context.url_compartilhamento || context.url[0]}
        />
        <meta property="og:type" content={"website"} />
        <meta
          property="og:site_name"
          content={context.nome_fantasia || context.nome}
        />
        <meta
          property="og:title"
          content={context.nome_fantasia || context.nome}
        />
        <meta property="og:image" content={context.logo} />
      </Helmet>
      {/* <HomeV2/> */}
      {homeSelect(context.info_gen && context.info_gen.home)}
    </>
  );
};

const homeSelect = home => {
  switch (home) {
    case 0:
      return <HomeV1 />;
    case 1:
      return <HomeV2 />;
    case 2:
      return <HomeV3 />;
    case 3:
      return <HomeV4 />;
    case 4:
      return <HomeV5 />;
    case 5:
      return <HomeV6 />;
    case 6:
      return <HomeV7 />;
    case 7:
      return <HomeV8 />;
    default:
      return <HomeV2 />;
  }
};

export default Home;
